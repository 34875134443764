.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5%;
  font-size: xxx-large;
  font-weight: 600;
  font-family: 'Roboto Mono', monospace;
}
.ele-1{
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ele-2{
  width: 60%;
}
.ele-2 a {
  margin: 3%;
  text-decoration: none;
  color: white;
  font-size: large;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
}
button.secondary-btn {
  border: none;
  padding: 3% 13%;
  border-radius: 30px;
  background: rgb(239, 176, 135);
  font-size: large;
  color: white;
  font-weight: 700;
}
#navbar-section {
  background-color: rgb(152, 156, 193);
  padding: 2%;
  position: fixed;
  width: 100%;
}
#footer-section{
  background-color: rgb(152, 156, 193);
  padding: 2%;
  margin-top: 5%;
}
body{
  margin: 0;
  font-family: sans-serif;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.secondary-text {
  font-size: xx-large;
  color: white;
  font-weight: 600;
  text-align: left;
  margin-top: 2%;
}
.primary-text {
  font-size: 500%;
  font-weight: 600;
  color: white;
  text-align: left;
  font-family: 'Roboto Mono', monospace;
}
#hero-section {
  background-image: url(https://images.unsplash.com/photo-1629905679177-4c4e2623654f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1836&q=80);
  padding: 14% 5%;
  background-size: cover;
  background-position-y: bottom;
}
.primary-btn {
  border: none;
  margin-right: 1%;
  padding: 1% 2%;
  width: 10%;
  border-radius: 30px;
  font-size: medium;
  font-weight: 700;
  background: rgb(239, 176, 135);
  color: white;
  margin-top: 2%;
  margin-bottom: 3%;
}
.word{
  display: none;
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
}
.word.active{
  display: block;
  text-decoration: underline;
  font-style: italic;
  font-family: 'Roboto Mono', monospace;
}
div#changing-words-block {
  margin-left: 1%;
}
.highlight-1{
  background-color: rgb(239, 176, 135);
}
.highlight-2{
  background-color: rgb(187, 213, 247);
}
.highlight-3{
  background-color: rgb(222, 140, 236);
}
.cursor-style{
  cursor: pointer;
}
.centered{
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1%;
  flex-wrap: wrap;
}
.text-sec {
  width: 65%;
  font-size: x-large;
}
.singular-btn{
  width: 50%;
  margin-right: 0%;
  padding: 3% 2%;
  margin-top: 12%;
}
.js-charts{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.chart-containers {
  flex: 0 0 23.33333%;
  padding: 3%;
  margin: 1%;
  flex-wrap: wrap;
}
h2.sub-title {
  margin-bottom: -2%;
  font-family: 'Roboto Mono', monospace;
}
.footer-notes{
  font-family: 'Roboto Mono', monospace;
  color: white;
  margin-top: 5%;
}
.team-member {
  background-color: rgb(187, 213, 247);
  margin: 1%;
  width: 25%;
  border: none;
  border-radius: 17px;
  padding: 4% 2%;
  padding-bottom: 5%;
}
.team-img {
  width: 72%;
  margin: 0%;
  border-radius: 12px;
}
.centered h3{
  font-family: 'Roboto Mono', monospace;
}
.hero-a{
  width: 10%;
  margin-right: 1%;
  margin-top: 2%;
}
html{
  scroll-behavior: smooth;
}
img.logo {
  position: absolute;
  width: 8%;
  left: 2%;
  top: 0%;
}
img.logo-2 {
  position: absolute;
  width: 8%;
}
.primary-btn-hero {
  border: none;
  margin-right: 1%;
  padding: 11% 3%;
  width: 100%;
  border-radius: 30px;
  font-size: medium;
  font-weight: 700;
  background: rgb(239, 176, 135);
  color: white;
  margin-top: 2%;
  margin-bottom: 3%;
}
.hide{
  display: none;
}
.faq-box {
  border: #989cc1 2px solid;
  width: 70%;
  text-align: left;
  margin: 1%;
  padding: 2%;
  border-radius: 20px;
}
.question{
  font-weight: 700;
  color: #efb087;
  font-size: x-large;
}
.answer {
  padding: 2%;
  font-size: larger;
}
.mobile-nav{
  display: none;
}
.ele-1 a {
  width: 100%;
}
div#popup-section {
  position: fixed;
  background: rgb(187, 213, 247);
  width: 25%;
  top: 55%;
  right: 1%;
  padding: 1%;
  border-radius: 13px;
}
.left-align {
  display: flex;
  padding: 1%;
}
#popup-section h2{
  font-family: 'Roboto Mono', monospace;
}
div#team-section {
  padding-top: 5%;
}

/* For Mobiles */
@media (max-width: 765px) {
  .ele-1 , .ele-2 {
    display: none;
  }
  .singular-btn {
    width: 57%;
    margin-top: 5%;
  }
  div#popup-section {
    width: 96%;
    top: 65%;
  }
  .primary-text {
    font-size: 400%;
    margin-top: 20%;
  }
  .secondary-text {
    font-size: x-large;
    font-weight: 500;
    margin-top: 10%;
    margin-bottom: 15%;
  }
  .text-sec {
    width: 90%;
    font-size: large;
    margin: 4%;
  }
  .primary-btn {
    padding: 3% 2%;
  }
  #singular-btn {
    width: 45%;
  }
  .chart-containers {
    flex: 0 0 60.33333%;
  }
  .faq-box {
    width: 87%;
    margin: 3%;
    padding: 3%;
  }.answer {
    font-size: medium;
  }
  #footer-section {
    padding: 6%;
    margin-top: 20%;
  }
  .team-member {
    margin: 4%;
    width: 65%;
    padding: 8% 2%;
    padding-bottom: 4%;
  }
  .row-center {
    margin-top: 10%;
  }
  .mobile-nav{
    display: block;
    text-align: left;
    padding: 2%;
  }
  div#mobile-menu {
    position: absolute;
    background: #989cc1;
    padding: 5%;
    text-align: left;
    width: 75%;
    border-radius: 12px;
  }
  #mobile-menu a {
    font-size: x-large;
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition-duration: 1s;
  }
  #mobile-menu h3 {
    margin-top: 5%;
  }
  #mobile-menu h3:hover{
    background-color: rgb(239, 176, 135);
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    border-radius: 12px;
  }
  .row{
    display: block;
  }
  .primary-btn-hero {
    margin-right: 0%;
    padding: 5% 3%;
    width: 50%;
    margin-top: 0%;
  }
}

/* For ipads */
@media (min-width: 770px) and (max-width: 900px) {
  .hero-a {
    width: 15%;
    margin-right: 2%;
    margin-top: 4%;
  }
  .ele-1 , .ele-2 {
    display: none;
  }
  .mobile-nav{
    display: block;
    text-align: left;
    padding: 2%;
  }
  div#mobile-menu {
    position: absolute;
    background: #989cc1;
    padding: 5%;
    text-align: left;
    width: 75%;
    border-radius: 12px;
  }
  #mobile-menu a {
    font-size: x-large;
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition-duration: 1s;
  }
  #mobile-menu h3 {
    margin-top: 5%;
  }
  #mobile-menu h3:hover{
    background-color: rgb(239, 176, 135);
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    border-radius: 12px;
  }
  div#popup-section {
    width: 40%;
  }
}